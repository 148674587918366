.landing-land-grid {
    @extend .row;
    text-align: center;

    $city-img-size: 280px;

    .city-img {
        transition: all .5s;
        transform-origin: center center;

        position: relative;
        display: inline-block;
        border-radius: 600px;
        width: $city-img-size;
        height: $city-img-size;

        //max-width: 100%;


        background-size: 100%;
        background-repeat: no-repeat;

        //&.berlin {
        //    background-image: url(../css/img/fotolia_55582966.jpg);
        //}
        //
        //&.rome {
        //    background-image: url(../css/img/fotolia_2355286.jpg);
        //}
        //
        //&.prague {
        //    background-image: url(../css/img/fotolia_59094645.jpg);
        //}

        h2 {
           color: #fff;
           text-shadow: 0 1px 0 #111;
           position: relative;
           margin: 0;

           top: 50%;
           transform: translateY(-50%);

           display: block;
           width: 100%;

           text-transform: uppercase;
           font-size: 4em;
           font-weight: bold;
        }

        &:hover {
            $city-img-size-margin: 20px;

            text-decoration: none;
            /*border: $city-img-size-margin solid #fff;
            width: $city-img-size - $city-img-size-margin * 2;
            height: $city-img-size - $city-img-size-margin * 2;*/

            /*background-size: 140%;
            background-position: -20% -20%;*/

            transform: scale(1.2);

        }
    }

    h2 {
        text-align: center;
    }
}
