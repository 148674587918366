.bc-calendar-frame {
	padding: 0 10px;
}

.bc-calendar-body {
	display: inline-block;
	padding: 10px 10px;
	text-align: center;
}

.bc-calendar-prev {
	padding: 10px 0;
	float:left;
	cursor: pointer;
}

.bc-calendar-next {
	padding: 10px 0;
	float:right;
	cursor: pointer;
}

.bc-calendar-month {
	text-align: center;
}

.bc-calendar-table {
	border-spacing:0;
	border-collapse:collapse;
}

.bc-calendar-table td {
	text-align: right;
	padding: 5px 10px;
}

.bc-calendar-table td.today {
	font-weight: bold;
}

.bc-calendar-table td.past, .bc-calendar-table td.future {
	color: #ccc;
}

.bc-calendar-table td.weekend {
	color: #333;
}


.bc-calendar-table td.free {
	background-color: #D1F2A5;
}

.bc-calendar-table td.busy, .bc-calendar-table td.unknown {
	background-color: #fbb3b8;
}

.bc-calendar-table td.free-busy {
	background-image: url(img/calendar_bkg_free-busy.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.bc-calendar-table td.busy-free {
	background-image: url(img/calendar_bkg_busy-free.png);
	background-position: 50% 50%;
	background-repeat: no-repeat;
}