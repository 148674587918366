$ca-green: #35701B;
$ca-green-active: lighten($ca-green, 10%);


html, body {height: 100%;}

body {
	background: #e7e7e8;
	background-color: #eee;
	/*background-image: url(http://colourlovers.com.s3.amazonaws.com/images/patterns/1704/1704094.png);*/
	font-family: 'Roboto', Arial, sans-serif;
}

body.fb {
	background-color: #fff;
	font-family: Tahoma, Arial, sans-serif;
}

.container {
	box-shadow: 0px 0px 10px #ccc;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 400;
}

h1, h2, h3 {
	font-family: 'Roboto Condensed', Helvetica, Arial, sans-serif;
}

h1 {
	margin-top: 0.5em;
}

h1 > small {
	display: block;
	line-height: 2;
}


.inside {
	margin: 0 auto;
	max-width: 90%;
	padding: 40px 0 40px 0;
	position: relative;
	height: 100%;
}


/* NAV */

.navbar {
	margin:0px;
}

.navbar .logo {
	display: inline-block;
	float:left;
}

.navbar-inner {
	background: none;
	border-width: 0;
	box-shadow: none;
}
/*
.navbar .btn-navbar {
	right: 0;
	position: absolute;
	top: 50%;
	height: 30px;
	margin: 0;
	margin-top: -15px;
	float: none;
}
*/

.navbar .nav {
	right: 0;
	position: absolute;
	top: 50%;
	height: 40px;
	margin: 0;
	margin-top: -20px;
	display: inline-block;
	left: auto;
	float: none;
}

.collapse {
	position: inherit;
}

.navbar .nav li a {
  font-weight: 400;
  color: #8f8f8f;
  font-size: 16px;
  line-height: 22px;
}

.navbar .nav li.active a {
  background-color: transparent;
  //background-color: #eee;
  border-radius: 2px;
  color: $ca-green-active;
  box-shadow: none;
  text-shadow: none;
}

.navbar .nav li.active a:hover {
  background-color: inherit;
  text-decoration: underline;
  color: #35701B;

  box-shadow: none;
  text-shadow: none;
}

/*
.navbar .navbar-inner {
	padding: 0px 0;
	margin-bottom: 0px;
	background-color: transparent;
	background-image: none;
	box-shadow:none;
	border-width: 0;
	height: auto;
}

.navbar .navbar-inner .nav > li > a {
	text-shadow: none;
	padding: 13px 15px;
	font-size: 16px;
	color: #666;
	border-radius: 3px;
}

.navbar .navbar-inner .nav > li > a:hover {
	color: #000;
	background-color:#fff;
}

.navbar .navbar-inner .nav > li.active > a {
	background: none;
	background-color: #35701B;
	color:#fff;
	box-shadow: none;
}
*/


/* WHITE CONTAINER*/

.main-container {
	background-color: #fff;
	border: 1px solid #DADBDC;
	border-top-width: 0;
	padding: 20px;
	padding-top: 0;
	border-radius: 0 0 10px 10px;
}

.main-container.all-corners {
	border-radius: 10px;
	border-top-width: 1px;
}


.rounded-bkg {
	padding: 10px;
	border-radius: 2px;
	background-color: rgba(255,255,255, .5);
}

/* CHECKINBOX */

.weather {
	opacity: 0.9;
	right: 60px;
	position: absolute;
	font-size: 36px;
	color: #fff;
	width: 170px;
	text-align: right;
}

.weather .data {
	margin-top: 10px;
}

.weather .data .small {
	display: block;
	font-size: 18px;
	line-height: 36px;
}

.checkinbox {
	/*background-color: rgba(0,0,0, .3);*/
	background-color:#fff;
	border-radius: 2px;
	padding: 10px;
}


/*
.checkinbox input {
	box-sizing: padding-box;
	padding: 4px 4px;
	width: 80px;
}

.checkinbox select {
	width: 160px;
}

.checkinbox .btn {
	vertical-align: 4px;
}

.checkinbox .btn-large {
	width: 200px;
	padding: 15px 10px;
}
*/


.btn {
	border-radius: 3px;
	border-width: 0;
}

.btn-success {
  background: $ca-green-active;
  border: none;
}

.btn-success:hover {
  background: lighten($ca-green-active, 5%);
}

input, select, textarea {
	border-radius: 2px;
}




.container {
	background-color: #fff;
	max-width: 1070px;
}

.bkg-lightgrey {
	background-color: #f7f7f7;
}

.bkg-highlight {
	background-color: #7E796C;
	background-image: url(../css/img/bkg-highlight.png);
	color:#fff;
}

.bkg-whitehighlight {
	background-color: #FCFBE3;
	margin: -10px;
	margin-bottom: 20px;
	padding: 10px;
	border-radius: 2px;
}
.bkg-whitehighlight .table {
	margin-bottom: 0px;
}




.ribbon {
 position: relative;
 background: #FCFBE3;
 color: #333;
 text-align: center;
 box-shadow: rgba(000,000,000,0.3) 0 1px 1px;
 z-index: 2	;
}

.ribbon:before, .ribbon:after {
	//font-size: 32px!important;
 content: "";
 position: absolute;
 display: block;
 bottom: -1em;
 border: 1.5em solid #FCFBE3;
 z-index: 1;
}
.ribbon:before {
 left: -2em;
 border-right-width: 1.5em;
 border-left-color: transparent;
 box-shadow: rgba(000,000,000,0.4) 1px 1px 1px;
}
.ribbon:after {
 right: -2em;
 border-left-width: 1.5em;
 border-right-color: transparent;
 box-shadow: rgba(000,000,000,0.4) -1px 1px 1px;
}
.ribbon .ribbon-content:before, .ribbon .ribbon-content:after {
 content: "";
 position: absolute;
 display: block;
 border-style: solid;
 border-color: #804f7c transparent transparent transparent;
 bottom: -1em;
}
.ribbon .ribbon-content:before {
 left: 0;
 border-width: 10em 0 0 1em;
}
.ribbon .ribbon-content:after {
 right: 0;
 border-width: 1em 1em 0 0;
}


.container-footer {
	background-color: #f7f7f7;
}

.footer {
	padding: 40px 0;
}


.hr-small {
	width: 240px;
	border-width: 0;
	border-top: 1px solid #eee;
}

.hr-center {
	margin-left: auto;
	margin-right: auto;
}


/* INDEX BACKGROUND */

.splash {
	//padding: 220px 20px 20px;

	background-size: cover;
	background-repeat: no-repeat;
	border-bottom-width:0;
	background-position: 0 0;

	background-color: #eee;
	width: 100%;
	min-height: 360px;
	height: 390px;
}

/********************************** SPLASH TEXT *****************************/

.splash .splash-text {
	position: absolute;
	top: 5em;
	//bottom: 3em;
	left: 2em;
	font-size: 28px;

	display: block;
	width: 70px;
	height: 70px;
	border-radius: 100px;
	padding:20px;

	background:rgba(255,255,255,0.7);
	text-align: center;

	//-webkit-transform:rotate(-12deg);
	//-moz-transform:rotate(-12deg);

	-webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.splash .splash-text:hover{
	/*
	-webkit-transform:rotate(0deg);
	-moz-transform:rotate(0deg);
	*/
	text-decoration: none;
	background:rgba(255,255,255,1);
	color: #000;

	padding:30px;
	margin: -10px;

}

.splash .splash-text .first-line {
	font-style: italic;
	font-family: serif;
	display: block;
	font-size: 18px;
	line-height: 14px;
}

.de .splash .splash-text .first-line {
	font-size: 16px;
}

.splash .splash-text .big-line {
	font-weight: 700;
	font-size: 32px;
	line-height: 36px;
}


.splash .splash-text a:hover {

	color: #000;
}




.splash.splash-large {
	padding-top: 300px;
}

.splash-subcontainer {
	position: absolute;
	right: 0px;
	bottom: 120px;
}

.bkg-white {
	text-shadow: 5px;
	padding: 20px;
	padding-top:0;
	margin-left: 0;
	/*-webkit-transform: skew(20deg);
	   -moz-transform: skew(20deg);
	     -o-transform: skew(20deg);*/
;
	//background-color: rgba(255,255,255, 0.9);
	background-color: rgba(0,0,0, 0.5);
	color: #fff;
	text-shadow: 0px 0px 5px #222;
}


.language {
	position: absolute;
	top:2em;
	right: 1em;
}

.language a {
	margin-right: 5px;
	opacity: 0.5;

	-webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

.language a:hover, .language a.active {
	text-decoration: none;
	opacity: 1;
}

.flag {
	width: 12px;
	height: 12px;
	background-image: url("../css/img/flags.png");
	display: inline-block;
	border-radius: 12px;
	background-repeat: no-repeat;
	box-shadow: 0 0 3px #aaa;
}

.flag.de { background-position: 0; }
.flag.ru { background-position: -12px; }
.flag.en { background-position: -24px; }



.index-bkg h1 {
	margin-bottom: 0;
	font-size: 48px;
	font-weight: normal;
	line-height: 1;
	//background-color: rgba(255,255,255, 1);
	//padding: 10px 20px;
	//width: 650px;
}

.index-bkg p {
	margin-top: 10px;
	font-size: 18px;
	font-weight: 200;
	line-height: 22px;
	color: inherit;
	//background-color: rgba(255,255,255, 1);
	//padding: 10px 20px;
	//width: 550px;
}

.bkg-reichstag {
	background-image: url(../img/bkg/reichstag.jpg);
	color: #fff;
}

.bkg-brandenburgertor {
	//background-image: url("../img/bkg/brandenburgertor.jpg");
	//color: #fff;
}

.bkg-alex {
    background-image: url(../img/bkg/alex.jpg);
}


.bkg-gedenkstaette {
	background-image: url(../img/bkg/gedenkstaette.jpg);
}

.bkg-charite {
    background-image: url(../img/bkg/charite.jpg);
}

.bkg-shopping {
    background-image: url(../img/bkg/shopping.jpg);
}


.table .cell-price {
	width: 70px;
}

.icon-wifi {
	background-image: url(../css/img/icon-wifi.png);
	background-position: 0 0;
}



.features {
	text-align: center;

	h3 {
		font-size: 22px;
		font-weight: 400;
		line-height: 22px;
	}

	.img-polaroid {
		border-width: 0;
	}

	.btn {
		min-width: 200px;
		padding: 10px;
		box-shadow: none;
	}
}

#map {
	width: 100%;
	height: 430px;
	padding-bottom: 0;
}

.no-padding {
	width: 980px;
	padding: 0;
		border: 1px solid #DADBDC;
	border-bottom-width: 0;


}

#map img {
  max-width: none;
}

.thumbnail-flat {
	width: 290px;
	height: 240px;
}

a, .thumbnail:hover a  {
	color: #000;
}


.thumbnail {
	border-radius: 0;
	position: relative;
}

.thumbnail img {
	width: 100%;
}

.thumbnail-capacity {
	position: absolute;
	top: 1em;
	right: 1em;
}

.thumbnail-overlay {
	position: absolute;
	z-index: 1;
	right: 0.5em;
	bottom: 0.6em;
}

.thumbnail-overlay:hover {
	color: #000;
}

.capacity-label {
	/*background-color: rgba(255, 255, 255, 0.5);
	color: #000;*/
	text-shadow: none;

	vertical-align: 2px;
	background-color: #eee;
	background-color: rgba(255,255,255,0.9);
	background-color: #fff;

	color: #000;
}

.thumbnail-overlay .price {
	text-transform: uppercase;
	display: block;
	text-align: right;
	font-size: 8px;
	line-height: 15px;

	width: 70px;
	//height: 56px;


	/*box-shadow: 2px 3px 0px rgba(0,0,0,0.5);*/
;
}

.thumbnail-overlay .price .big {
	font-size: 24px;
	//font-weight: bold;
	display:block;
	/*line-height: 28px;*/
}




.rows3 {
	height: 3em;
}


.thumbnail .caption {
	height: 2.5em;
	overflow: hidden;
	margin-right: 4em;
	text-overflow: ellipsis;
	white-space: normal;
}


.bkd1-container {
	background-image: url(../img/bkg/btor.jpg);
	background-repeat: repeat-x;
	background-color: #fafafa;
	height: 500px;
}


.flat-photo .carousel-inner .item {
	text-align: center;
}
.flat-photo .carousel-inner .item img {
	width: 100%;
}

.flat-photo .carousel-control {
	top: 40px;
	border-width: 0;
	background-color: #fff;
	color: #111;
}



/******************************** U + S *******************************/

.ubahn, .sbahn, .bvg {
	border-radius:6px;
	background-color:#333;
	color:#fff;
	padding:0 4px;
	font-weight: bold;
	white-space:nowrap;
	font-weight: normal;
	font-size: 12px;
}

.sbahn {
	background-color:#006F35;
}

.ubahn {
	background-color:#156AB8;
}

.bvg {
	background-color:#F8EC00;
	color: #141414;
}


/********************************** CC ***********************************/
.cc {
	padding: 0px 0px 0 0;
	display: inline-block;
	height: 24px;
	width: 38px;
	background-repeat: no-repeat;
	background-position: 0px 0px;
	vertical-align: 0;
}

.cc.visa { background-image:url(../css/img/cc_visa.png); }
.cc.mastercard { background-image:url(../css/img/cc_mastercard.png); }
.cc.paypal { background-image:url(../css/img/cc_paypal.png); }
.cc.western-union { background-image:url(../css/img/cc_western-union.png); }
.cc.amex { background-image:url(../css/img/cc_amex.png); }


.row-fluid ul.thumbnails li.span4:nth-child(3n + 4) { margin-left : 0px; }

[class^="icon-"], [class*=" icon-"] {
	margin-top: 2px;
}


@media (max-width: 979px) {
	.navbar .nav {
		position: relative;
		height: auto;
		margin: 0;
	}

	body {
		padding: 0;
	}

	h1 {
		font-size: 32px;
		line-height: 36px;
	}
}


.row-fluid {
    ul.thumbnails {
        li.span12 + li { margin-left : 0px; }
        li.span6:nth-child(2n + 3) { margin-left : 0px; }
        li.span4:nth-child(3n + 4) { margin-left : 0px; }
        li.span3:nth-child(4n + 5) { margin-left : 0px; }
        li.span2:nth-child(6n + 7) { margin-left : 0px; }
        li.span1:nth-child(12n + 13) { margin-left : 0px; }
    }
}

.form-control-static {
    padding-top: 5px;
    margin-bottom: 0;
}

#description {
  .booking-button-container {
	display: inline-block;
	border-radius: 3px;
	margin-top: 1em;
	padding: 1em;
	background-color: lighten(#5bb75b, 30%);
  }
}
