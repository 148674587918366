$features: 'eg' '1' '2' '3' '4' '5' 'air' 'balcony' 'bus' 'child' 'dishes' 'intercom' 'kitchen' 'lift' 'p' 'people' 'pets' 'point' 'price' 'smoke' 'tv' 'iron' 'wd' 'wifi' 'wm' 'work_place';

@mixin feature($name) {

}

.feature {
  display: inline-block;
  width: 48px;
  height: 48px;
  background-repeat: no-repeat;
  margin: 0 5px 5px 0;
  padding: 5px;
  background-color: #f7f7f7;
  background-position: center;

  @each $feature in $features {
    &.feature-#{$feature} {
      background-image: url("img/features/#{$feature}.png");
    }
  }
}
