#manager-images {
    .thumb-list {
        .thumb {
            display: block;
            padding: .5em;
            border: 1px solid #ccc;
            background-color: #f7f7f7;
            margin-bottom: 1em;
            width: 320px;

            .actions {
                cursor: pointer;
            }
        }
    }
}
